/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
    font-family: "Oswald Italic";
    font-weight: 700;
    src: url('../asset/fonts/Oswald/Oswald-Bold-Italic-700.ttf') format('truetype');
}

/*
  * Dark Colors
  * -------------------------------------------
  */
:root {
    --ion-color-primary: #A8FF00;
    --ion-color-primary-rgb: 168, 255, 0;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 40, 39, 39;
    --ion-color-primary-shade: #EBF4DA;
    --ion-color-primary-tint: #C4FD52;

    --ion-color-secondary: #282727;
    --ion-color-secondary-rgb: 40, 39, 39;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #302F2F;
    --ion-color-secondary-tint: #282727;

    --ion-color-tertiary: #2738C1;
    --ion-color-tertiary-rgb: 39, 56, 193;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #2738C1;
    --ion-color-tertiary-tint: #2738C1;

    --ion-color-success: #A8FF00;
    --ion-color-success-rgb: 168, 255, 0;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 40, 39, 39;
    --ion-color-success-shade: #C4FD52;
    --ion-color-success-tint: #a8ff001a;

    --ion-color-warning: #FF9500;
    --ion-color-warning-rgb: 255, 169, 0;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #FF0028;
    --ion-color-danger-rgb: 255, 0, 40;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #FD274D;
    --ion-color-danger-tint: #FD274D;

    --ion-color-dark: #282727;
    --ion-color-dark-rgb: 40, 39, 39;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #302F2F;
    --ion-color-dark-tint: #282727;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #F4F4F4;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #F4F4F4;
    --ion-color-light-tint: #F4F4F4;
}

/*
  * iOS Dark Theme
  * -------------------------------------------
  */

.ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
}

.ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
}


/*
  * Material Design Dark Theme
  * -------------------------------------------
  */

.md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
}

@keyframes scrollContent {
    0% { transform: translateX(0); }
    30% { transform: translateX(0); }
    100% { transform: translateX(-40%); }
}
